.progression-logo {
	text-transform: lowercase;
	font-size: 21px;
	font-family: Raleway;
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		font-size: 18px;
	}

	.progression {
		color: @logo-primary;	
		font-weight: 600;
	}

	.equipment {
		margin-left: -5px;
		color: @logo-secondary;
		font-weight: 400;
	}
}

.home {
	img.logo-black {
		display: none;
	}
	img.logo-white {
		display: inline-block;
	}
}

.template-contact {
	img.logo-white {
		display: none;
	}
	img.logo-black {
		display: inline-block;
	}
}

h2 {
	text-transform: lowercase;
	font-family: Raleway;
	font-size: 26px;

	.progression {
		color: @logo-primary;	
		font-weight: 600;
	}

	.equipment {
		color: @logo-secondary;
		font-weight: 400;
		padding-left: 3px;
	}
}


.home .progression-logo {
	.equipment {
		color: #FFF;
	}
}

header nav {
	float: right;
	padding-top: 15px;
}

.navbar-brand {
	color: #000;
	font-weight: 600;
	font-size: 22px;
	padding-top: 17px;

	@media (min-width: @screen-sm-min) {
		font-size: 26px;
		padding-top: 26px;
	}
}

.navbar {
	margin-bottom: 5px;
}

.navbar-toggle {
	margin-top: 15px;
	margin-bottom: 0;
	padding-bottom: 0;

	i {
		font-weight: @bold;
		font-size: 22px;
	}
}

.navbar-nav .open .dropdown-menu > li > a {
	line-height: 20px;
}


.navbar-nav > li > a {
	color: @black;
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	padding: 15px 45px 15px 0;

	&:hover, &:active {
		background: none;
	}

	i {
		font-size: 16px;
		font-weight: @bold;
	}

	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		font-size: 13px;
		padding: 15px 20px 15px 0;
	}
}

.navbar-nav > li.with-slider > a {
	display: inline-block;

	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 0;
		background: transparent;
		transition: all .3s;
	}

	&:hover:after {
		width: 100%;
		background: @red;
	}
}

@media (min-width: @screen-sm-min) {
	.navbar-nav > li > .dropdown-menu {
		border-radius: @border-radius-large;
		background-color: rgba(255,255,255,0.9);
		border: none;
		box-shadow: 0 1px 3px rgba(0,0,0,0.5);
		margin-left: -20px;
	}
}

.dropdown-menu > li > a,
.dropdown-menu > li.active > a {
	text-transform: uppercase;
	padding: 5px 20px;
	color: @black;
	font-weight: 600;
	font-size: 14px;
	background-color: transparent;
	display: block;
	/*
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 0;
		background: transparent;
		transition: all .3s;
	}

	&:hover:after {
		width: 100%;
		background: @red;
	}
	*/

	&:hover {
		background-color: @black;
		color: #FFF;
	}
}

@media (min-width: @screen-sm-min) {
	.navbar-nav > li.open > a i {
		color: @red;
	}
}

.menu-language-switcher {
	padding-top: 15px;
}

.home {
	.navbar-brand {
		color: #FFF;
	}

	.navbar-nav > li > a,
	.navbar-nav > li.open > a {
		color: #FFF;
	}

	.menu-language-switcher {
		color: #FFF;

		a {
			color: #FFF;
		}
	}

	.navbar-nav > li > .dropdown-menu {
		background-color: rgba(50, 50, 50, 0.6);
	}
	@media (max-width: @screen-xs-max) {
		.navbar-nav > li > .dropdown-menu {
			background-color: transparent;
		}
	}

	.dropdown-menu > li > a,
	.dropdown-menu > li.active > a {
		color: #FFF;	
	}
}

.sliding-u-l-r {
display: inline-block;
}

@media (max-width: @screen-xs-max) {
	header nav {
		padding-top: 0;
	}

	.navbar-toggle {
		margin-top: 7px;
	}

	.navbar-collapse {
		position: absolute;
		right: 0;

		.navbar-nav {
			margin: 0;
			background-color: rgba(255,255,255,0.8);

			li a {
				padding: 5px 30px;
			}
		}
	}

	.dropdown-menu {
		display: block;
		position: inherit;
		border: none;
		box-shadow: none;
		padding-left: 15px;
		padding-top: 0px;
		padding-bottom: 0px;
		background-color: transparent;
	}

	.menu-language-switcher {
		padding: 10px 20px 20px;

		a {
			padding: 5 20px;
		}
	}

	.home {
		.navbar-toggle {
			color: #FFF;
		}

		.navbar-collapse {
			.navbar-nav {
				background-color: rgba(50, 50, 50, 0.7);
				padding-top: 10px;
			}
		}
	}
}
