.form-wrapper {
	background-color: #E0E0E0;
	padding: 20px;
	border-radius: @border-radius-large;
}

.ajax-form {
	&.has-error {
		input, select, textarea {
			border-color: @red;

			&:focus {
				border-color: @red;
			}
		}
	}

	input, select, textarea {
		border-width: 2px;
		border-color: #F0F0F0;

		&:focus {
			border-color: #999;
			outline: none;
			.box-shadow(none);
		}
	}

	.btn-submit {
		margin-top: 10px;
	}
}

.show-after-submit {
	display: none;
}