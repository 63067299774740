ul.social-icons {
	padding: 0;
	list-style-type: none;

	li {
		display: inline-block;

		a {
			display: table-cell;
			width: 32px;
			height: 32px;
			background-color: @black;
			color: #FFF;
			text-align: center;
			vertical-align: middle;
			.transition(all 0.2s linear);

			i {
				font-size: 20px;
				vertical-align: middle;
			}
		}
	}

	.social-facebook a:hover {
		background-color: #3B5998;
	}

	.social-twitter a:hover {
		background-color: #55ACEE;
	}

	.social-google-plus a:hover {
		background-color: #DD4B39;
	}

	.social-linkedin a:hover {
		background-color: #0976B4;
	}

	.social-instagram a:hover {
		background-color: #517fa4;
	}

	.social-youtube a:hover {
		background-color: #bb0000;
	}

	.social-vimeo a:hover {
		background-color: #90d5ec;
	}

	.social-pinterest a:hover {
		background-color: #cb2027;
	}
}