footer {
	margin-top: 50px;
	padding: 20px 0;
//	border-top: 2px solid #000;	

	.textwidget {
		font-weight: 500;
		color: @black;
		font-size: 16px;
		padding-top: 10px;

		ul {
			padding-left: 5px;
			list-style-type: none;
			display: inline-block;

			li {
				display: inline-block;

				a {
					color: @black;
					margin-right: 10px;
					color: #666;

					&:hover {
						color: @red;
					}

					i {
						font-size: 13px;
						padding-right: 3px;
					}
				}
			}
		}
	}

	@media (max-width: @screen-xs-max) {
		.textwidget {
			text-align: center;
		}

		.pull-right {
			width: 100%;
			text-align: center;
		}
	}
}
