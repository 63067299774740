// Grid settings
@main-sm-columns:       @grid-columns;
@sidebar-sm-columns:    4;
@font-size-base: 16px;


//@font-size-h1: 26px;
@font-size-h2: 26px;
@font-size-h3: 20px;

@btn-primary-color: @red;
@btn-primary-bg: transparent;
@btn-primary-border: @red;

@grid-gutter-width: 20px;

@carousel-indicator-border-color: #CCC;
@carousel-control-color: #CCC;
@carousel-indicator-active-bg: #CCC;


@screen-lg:                  1400px;
@bold: 600;

// Colors
@brand-primary:         @red;
@logo-primary: #E60B1F;
@logo-secondary: #232323;
@red: #C90100;
@blue: #CD1100;
@black: #333;
@grey: #333;
@btn-primary-color: @black;
@btn-primary-border: @black;

@font-family-sans-serif: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;

@link-hover-color: #000;
@link-hover-decoration: none;
@nav-link-hover-bg: transparent;
@dropdown-bg: #FFF;
@dropdown-border: @black;
@dropdown-link-color: @black;
@navbar-border-radius: 6px;