.stores {
	.sidebar {
		background-color: #F0F0F0;
		padding: 20px;

		p {
			font-size: 14px;
		}

		ul.brand-categories {
			list-style-type: none;
			padding: 0;
			font-size: 15px;

			li {
				&.all-brands {
					border-bottom: 2px solid #AAA;
					margin-bottom: 8px;
				}

				&:nth-of-type(even) {
					background-color: #E0E0E0;

					span.store-count {
						background-color: #D0D0D0;
					}
				}

				a {
					display: block;
					color: @black;
					font-weight: 500;
					padding: 5px 10px;

					&:hover {
						background-color: @black;
						color: #FFF;

						span.store-count {
							background-color: #666;
							color: #CCC;
						}

						span.indicator {
							border-color: #666;
							background-color: #666;		
						}
					}

					&.active {
						font-weight: 600;

						span.indicator {
							border-color: #AAA;
							background-color: #AAA;		
						}
					}
				}
			}

			span.store-count {
				float: right;
				background-color: #E0E0E0;
				border-radius: 6px;
				border-radius: 6px;
				width: 25px;
				text-align: center;
				font-size: 14px;
				font-weight: 600;
				color: #888;
			}

			span.indicator {
				display: inline-block;
				width: 10px;
				height: 10px;
				margin: 1px;
				border: 2px solid #CCC;
				border-radius: 10px;
				margin-right: 10px;
			}
		}
	}

	.table {
		font-size: 14px;
		padding-left: 10px;

		th.sortable {
			color: @black;

			&:hover {
				cursor: pointer;
				color: @red;
			}	

			span {
				display: none;
			}

			&[data-sorted-direction="ascending"] .glyphicon-menu-down {
				display: inline-block;
			}

			&[data-sorted-direction="descending"] .glyphicon-menu-up {
				display: inline-block;
			}
		}

		th:first-child {
			padding-left: 20px;
		}

		td, th {
			border: none;
		}
	}

	.store-location {
		padding: 15px 10px 10px 20px;

		h3 {
			font-size: 15px;
			font-weight: 600;
			margin-top: 0px;
			margin-bottom: 10px;
			border-bottom: 2px solid @red;
			padding-bottom: 3px;
			display: inline-block;
			padding-right: 10px;
		}

		.glyphicon {
			margin-left: -20px;
			padding-right: 5px;
			color: @red;
		}

		.store-address,
		.store-phone {
			padding-left: 20px;
		}

		.store-brands {
			margin-top: 10px;
			a {
				color: @black;
				font-weight: @bold;

				&:hover {
					color: @red;
				}
			}
		}
	}

	.store-city {
		padding-top: 45px;
		font-weight: 600;
	}
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #F5F5F5;
}
