body.home {
	.banner {
		position: absolute;
		width: 100%;
	}

	.progression-logo,
	.navbar-nav > li > a {
		text-shadow: 0px 1px 1px rgba(0,0,0, 0.10);
	}



	h1 {
		padding-top: 30%;
		font-size: 70px;
		color: #FFF;
		text-transform: lowercase;
		letter-spacing: 3px;
		text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
		line-height: 1.1em;
		padding-bottom: 0px;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
	}

	.tagline {
		color: #FFF;
		width: 100%;
		font-size: 20px;
		font-size: 20px;
		padding-top: 10px;
		padding-bottom: 60px;
		text-align: left;
		font-size: 70px;
	}

	@media (max-width: @screen-xs-max) {
		h1 {
			font-size: 42px;
		}

		.tagline {
			font-size: 17px;
		}

		.logo-wrapper {
			max-width: 100%;
			img {
				max-width: 90%;
			}
		}
		
		#fp-nav.right {
			right: -8px;
		}	

		#fp-nav ul li {
			height: 10px;
		}

		#fp-nav ul li a span, .fp-slidesNav ul li a span {
			width: 5px;
			height: 5px;
		}
	}

	.section i.fa-angle-down {
		color: @red;
		font-size: 40px;
	}

	.logo-wrapper {
		position: absolute;
		bottom: 160px;
		text-align: left;
		background-color: rgba(255,255,255,0.8);
		padding: 50px 50px 50px 9500px;
		margin-left: -10000px;

		img {
			width: 450px;
			display: block;
		}
	}

	.button-wrapper {
		position: absolute;
		bottom: 80px;
		text-align: left;
		z-index: 100;
	}

	.visit-site {
		background-image: url('/assets/images/arrows-red.png');
		background-position: right;
		background-position: right;
		height: 25px;
		display: inline-block;
		clear: both;
		background-repeat: no-repeat;
		padding-right: 35px;
		background-size: contain;
		line-height: 23px;
		color: #000;
		margin-top: 20px;
	}
}

#fullpage .section {
	background-size: cover;
}

.fp-tableCell {
	text-align: center;
	display: block;
	position: relative;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
	background: rgba(255, 255, 255, 0.40);
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
	top: 2px;
	left: 2px;
	width: 8px;
	height: 8px;
	background: rgba(255,255,255,0.4);
	border-radius: 50%;
	position: absolute;
	z-index: 1;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span,
#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span {
	background: #ffffff;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin: -2px 0 0 -2px;
}