.news {
	.sidebar {
		background-color: #F0F0F0;
		padding: 20px;

		p {
			font-size: 14px;
		}
	}

	.article {
		margin-bottom: 20px;

		.image-wrapper {
			display: block;
			position: relative;
			background-size: cover;

			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				padding-top: 75%;
				background-color: transparent;
				transition: all 0.3s;
			}

			&:hover:before {
				background-color: rgba(255,255,255,0.3);
			}

			margin-bottom: 15px;
		}

		.embed-responsive {
			margin-bottom: 15px;
		}

		h3 {
			margin-bottom: 8px;
		}

		ul.article-meta {
			list-style-type: none;
			padding: 0;
			color: #666;
			font-size: 15px;

			li {
				display: inline;

				a {
					color: @black;
					font-weight: 500;

					&:hover {
						color: @red;
					}
				}
			}
		}

		.article-content {
			margin-top: 15px;
			margin-bottom: 15px;
		}

		.news-gallery {
			.news-thumbnail {
				padding-bottom: 15px;
			}
		}
	}
}

.news.news-item {
	h3 {
		font-size: 28px;
		color: @red;
		padding-top: 10px;
	}

	@media (max-width: @screen-xs-max) {
		#sidebar {
			display: none;
		}
	}
	.article .image-wrapper {
		img {
			.img-responsive();
		}

		&:before {
			position: absolute;
			padding: 0;
		}
	}
}