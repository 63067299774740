@font-face {
	font-family: 'icomoon';
	src:url('/assets/fonts/icomoon.eot?fbrgpy');
	src:url('/assets/fonts/icomoon.eot?#iefixfbrgpy') format('embedded-opentype'),
		url('/assets/fonts/icomoon.woff?fbrgpy') format('woff'),
		url('/assets/fonts/icomoon.ttf?fbrgpy') format('truetype'),
		url('/assets/fonts/icomoon.svg?fbrgpy#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
	content: "\f099";
}
.icon-facebook:before {
	content: "\f09a";
}
.icon-google-plus:before {
	content: "\f0d5";
}
.icon-linkedin:before {
	content: "\f0e1";
}
.icon-angle-left:before {
	content: "\f104";
}
.icon-angle-right:before {
	content: "\f105";
}
.icon-angle-down:before {
	content: "\f107";
}
.icon-youtube:before {
	content: "\f167";
}
.icon-phone:before {
	content: "\e608";
}
.icon-location-pin:before {
	content: "\e607";
}
.icon-documents:before {
	content: "\e605";
}
.icon-cancel-circle:before {
	content: "\e604";
}
.icon-link:before {
	content: "\e603";
}
.icon-circle-left:before {
	content: "\e602";
}
.icon-circle-right:before {
	content: "\e601";
}
.icon-vimeo:before {
	content: "\e600";
}