#map-canvas {
	width: 100%;
	height: 400px;
}

.contact-main {
	background-color: #F7F7F7;
    overflow: hidden; 

	margin-top: 20px;
	@media (min-width: @screen-sm-min) {
		margin-top: 50px;
	}

	.row {
	    overflow: hidden; 
	    padding-bottom: 20px;
	}

	//Make columns the same height
	[class*="col-"]{
	    margin-bottom: -99999px;
	    padding-bottom: 99999px;
	}


	.contact-form {
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 37px;

		h2 {
			margin-bottom: 5px;
		}

		textarea {
			height: 10em;
		}

		.form-wrapper {
			margin-top: 20px;
		}
	}
	.contact-info {
		background-color: #f0f0f0;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 30px;
	}

	.contact-address {
		margin-top: 35px;
	}

	.brochure-icon {
		background-color: @red;
		width: 70px;
		height: 70px;
		border-radius: 45px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding-top: 20px;

		i {
			color: #FFF;
			font-size: 40px;
		}
	}

	h4 {
		font-size: 16px;
		font-weight: @bold;
		margin: 20px 0 5px;
	}
}

.social-links {
	padding: 0;
	list-style-type: none;

	li {
		display: inline;

		a {
			display: inline-block;
			width: 48px;
			height: 48px;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				transition: all 0.3s;
			}

			&:hover:after {
				background-color: rgba(255,255,255,0.2);
			}
		}
	}

	a.facebook {
		background-image: url('/assets/icons/48x48/facebook.png');
	}

	a.twitter {
		background-image: url('/assets/icons/48x48/twitter.png');
	}

	a.googleplus {
		background-image: url('/assets/icons/48x48/googleplus.png');
	}
}