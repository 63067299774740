.btn {
	border-width: 2px;
}

.btn-primary {
	&:hover {
		color: #FFF;
		background-color: @black;
	}
	
	.transition(all 0.2s linear);
}

.btn-home {
	.button-variant(#FFF; transparent; #FFF);
	.button-size(14px; 25px; 16px; @line-height-large; @border-radius-large);
	font-weight: 600;
	border-width: 2px;
	letter-spacing: 2px;
	text-transform: uppercase;

	&:hover,
	&:focus,
	&:active {
		color: black;
		background-color: #FFF;
		border-color: #FFF;
	}

	.transition(all 0.2s linear);

	padding-top: 9px;
	
	i {
		font-weight: @bold;
		font-size: 19px;
		padding-left: 5px;
		background-image: url('/assets/images/arrows.png');
		display: inline-block;
		width: 25px;
		height: 25px;
		background-size: cover;
		position: relative;
		top: 6px;
		left: 6px;
	}
}

.btn-submit {
	.button-variant(@red; transparent; @red);
	.button-size(10px; 16px; 16px; @line-height-large; @border-radius-large);
	font-weight: 600;
	border-width: 2px;
	letter-spacing: 2px;
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		letter-spacing: 0px;
	}

	text-transform: uppercase;
	width: 100%;

	&:hover,
	&:focus,
	&:active {
		color: #FFF;
		background-color: @red;
		border-color: @red;
	}

	.transition(all 0.2s linear);

	img {
		display: none;
	}
}

.submitting .btn-submit {
	background-color: @red;
	color: #FFF;

	img {
		display: inline-block;
	}
}
