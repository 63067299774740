.brand-gallery {
	display: block;
	.col-sm-4,
	.col-sm-8 {
		padding: 0;
	}

	.brand-thumbnail {
		float: left;
		width: 25%;
		padding: 2px;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.col-sm-4 .brand-thumbnail {
		width: 100%;
	}

	.additional-images {
		display: none;

		img {
			display: none;
		}
	}
}

.brand-main {
	margin-top: 30px;
	background-color: #F7F7F7;
	overflow: hidden;


	.row {
	    overflow: hidden; 
	    padding-bottom: 20px;
	}

	//Make columns the same height
	[class*="col-"]{
	    margin-bottom: -99999px;
	    padding-bottom: 99999px;
	}

	.logo-wrapper {
		margin-bottom: 22px;

		img {
			max-width: 100%;
			height: auto;
		}
	}

	.brand-description {
		font-size: 16px;
		line-height: 1.5em;
		padding-top: 25px;
		padding-left: 30px;
		padding-right: 20px;
		//Don't set padding-bottom ... see above

		@media (min-width: @screen-sm-min) {
			width: 60%;	
		}
	}

	.brand-req-catalog {
		padding-top: 30px;
		padding-left: 20px;
		padding-right: 30px;
		//Don't set padding-bottom ... see above

		border-right: 2px solid #eee;
		text-align: center;
		@media (min-width: @screen-sm-min) {
			width: 40%;	
		}
		background-color: #f0f0f0;

		h2 {
			margin: 20px 0;
		}

		p {
			font-weight: 500;
			font-size: 16px;
		}

		.gfield_label {
			display: none;
		}

		.gform_wrapper {
			background-color: #E0E0E0;
			padding: 10px 10px 20px;
			border-radius: @border-radius-large;
		}

		.gform_footer {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.brochure-icon {
		background-color: @red;
		opacity: 0.8;
		width: 90px;
		height: 90px;
		border-radius: 45px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		padding-top: 20px;

		span {
			color: #FFF;
			font-size: 50px;
		}
	}
}

.brand-stores {
	margin-top: 30px;

	h2 {
		font-size: 26px;
		padding-left: 20px;
	}
	
	.row {
		padding: 0px 18px 14px
	}

	/*
	.row:first-child {
		padding-top: 18px;
	}

	.row:last-child {
		padding-bottom: 18px;
	}
	*/

	.col-md-4 {
		padding-left: 7px;
		padding-right: 7px;
		padding-top: 18px;
	}
}

.brand-stores .carousel {
	background-color: #F7F7F7;
	padding-bottom: 10px;


	@media (min-width: @screen-sm-min) {
		&.multi-row .item {
			height: 420px;
		}
	}

	.carousel-inner {
		padding: 0 5px 50px;
	}

	.carousel-control {
		background: none;
		bottom: 30px;

		i {
			position: absolute;
			font-size: 25px;
			top: 100%;

			&:hover {
				color: @red;
			}
		}

		&.left i {
			left: 30px;
		}

		&.right i {
			right: 30px;
		}
	}

	.carousel-control.left,
	.carousel-control.right {
		text-shadow: none;
	}

	.carousel-indicators {
		bottom: 5px;

		li {
			border-width: 2px;
			margin: 0;

			&.active {
				width: 10px;
				height: 10px;
			}

			&:hover {
				border-color: @red;
				background-color: @red;
			}
		}
	}
}

.row.brand-stores {
	margin-left: -5px;
	margin-right: -5px;

	.col-sm-4 {
		padding-left: 5px;
		padding-right: 5px;
	}
}

.brand .store-location {
	background: #FFF;
	padding: 20px 5px 20px 20px;
	border: 1px solid #F0F0F0;
	border-bottom: 1px solid #CCC;
	font-size: 14px;

	h3 {
		font-size: 14px;
		font-weight: 600;
		margin-top: 0px;
		margin-bottom: 10px;
		border-bottom: 2px solid @red;
		padding-bottom: 3px;
		display: inline-block;
		padding-right: 10px;
	}

	i {
		margin-left: -20px;
		padding-right: 5px;
		color: @red;
	}

	.store-address {
		min-height: 80px;
		margin-left: 20px;
	}

	.store-city-postcode {
		font-weight: @bold;
	}

	.store-country,
	.store-phone {
		clear: both;
	}

	.store-phone {
		margin-top: 10px;
		margin-left: 20px;
	}
}