.image-gallery {
	a {
		display: block;
		position: relative;

		&:before {
			content: '';
			width: 100%;
			height: 100%;
			background: none;
			position: absolute;
			transition: all 0.3;
		}

		&:hover {
			cursor: pointer;

			&:before {
				background-color: rgba(255,255,255,0.5);
			}
		}

		img {
			.img-responsive();
		}
	}
}

.modal[style],
.modal-open[style] {
	padding-right: 0px !important;
}

.modal-body {
	height: 100%;
	padding: 0;

	div {
		height: 100%;
}

	img.img-responsive {
		max-height: 100%;
	}

	.ekko-lightbox-container {
		position: relative;
	}

	.image-wrapper {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    right: 0;

	    text-align:center; /* Align center inline elements */
	    font: 0/0 a;

	    img {
    	    vertical-align: middle;
		    display: inline-block;
	    }

	    &:before {
		    content: ' ';
		    display: inline-block;
		    vertical-align: middle;
		    height: 100%;
		}
	}
}

.ekko-lightbox-nav-overlay {
	a.lightbox-nav {
		float: left;
		z-index: 1;

		div {
			position: relative;

			span {
				position: absolute;
				top: 50%;
			}
		}
	}

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		color: #FFF;
		z-index: 100;
	}
}

.ekko-lightbox {
	.icon-angle-right {
		padding-right: 30px;
		float: right;
		right: 0;
		text-align: right;
		font-size: 30px;
		font-weight: @bold;
	}

	.icon-angle-left {
		padding-left: 30px;
		float: left;
		left: 0;
		text-align: left;
		font-size: 30px;
		font-weight: @bold;
	}

	.close {
		color: #666;
		opacity: 1;
		font-weight: normal;
		text-shadow: none;

		&:hover {
			color: #FFF;
		}
	}
}

.modal-dialog {
	width: 100%;
	height: 100%;
	padding: 0;
	margin:0;
	background-color: transparent;
}

.modal-content {
	border: none; 
	overflow: hidden;
	height: 100%;
	border-radius: 0;
	color: white;
	overflow: hidden;
	padding: 0;
	background-color: rgba(0,0,0,0.75);
}

.modal-title
{
	font-size: 3em;
	font-weight: 300;
	margin: 0 0 20px 0;
}