// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:less
@import "../../bower_components/bootstrap/less/bootstrap.less";
// endbower
@import "../../bower_components/ekko-lightbox/ekko-lightbox.less";

@import "layout.less";
@import "nav.less";
@import "footer.less";
@import "page-home.less";
@import "page-brand.less";
@import "page-news.less";
@import "page-stores.less";
@import "page-contact.less";
@import "gravityforms.less";
@import "components/buttons.less";
@import "components/forms.less";
@import "components/language-switcher.less";
@import "components/lightbox.less";
@import "components/social.less";

@import "common/_variables.less";
@import "iconmoon.less";
.coming-soon {
	.banner .container nav.navbar-collapse, footer {
		display: none !important;
	}
}

h1, h2, h3,
.h1, .h2, .h3 {
	margin-top: 0;
}

/*
@import "common/_global";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";
*/

/*
.alternatives {
	main {
		min-height: 500px;
	}

	.banner,
	header {
		margin-bottom: 65px;
	}

	.menu-language-switcher {
		display: none !important;
	}
}

.option {
	display: block;
}

.color-option {
	display: block;
	margin-bottom: 50px;
}

.color-1 {
	.navbar-brand {
		color: #e60b1f;

		.equipment {
			color: #000;
		}
	}

	.solid .equipment {
		color: #e60b1f;
	}
}

.color-2 {
	.navbar-brand {
		color: #204e62;

		.equipment {
			color: #4facaf;
		}
	}

	.solid .equipment {
		color: #204e62;
	}
}

.color-3 {
	.navbar-brand {
		color: #f36533;

		.equipment {
			color: #666;
		}
	}

	.solid .equipment {
		color: #f36533;
	}
}

.color-4 {
	.navbar-brand {
		color: #0165c7;

		.equipment {
			color: #655373;
		}
	}

	.solid .equipment {
		color: #0165c7;
	}
}

.color-5 {
	.navbar-brand {
		color: #83bb58;

		.solid .equipment {
			color: #83bb58;
		}

		.equipment {
			color: #b7e67e;
		}
	}
}

#option-1 {
	font-family: Raleway;

	.navbar-brand {
		font-weight: 500;
		font-family: Raleway;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-2 {
	font-family: Montserrat;
	font-weight: 400;

	.navbar-brand {
		font-weight: 400;
		font-family: Montserrat;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-3 {
	font-family: Roboto;
	font-weight: 400;

	.navbar-brand {
		font-weight: 500;
		font-family: Roboto;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-4 {
	font-family: 'Roboto Condensed';
	font-weight: 400;

	.navbar-brand {
		font-weight: 700;
		font-family: 'Roboto Condensed';
	}

	.navbar-nav > li > a {
		font-family: Roboto;
		font-weight: 400;
	}
}

#option-5 {
	font-family: 'Open Sans';
	font-weight: 600;

	.navbar-brand {
		font-weight: 600;
		font-family: 'Open Sans';
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-6 {
	font-family: Lato;
	font-weight: 400;

	.navbar-brand {
		font-weight: 700;
		font-family: Lato;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-7 .equipment,
#option-8 .equipment,
#option-9 .equipment,
#option-10 .equipment,
#option-11 .equipment,
#option-12 .equipment {
	padding-left: 2px;
}

// Lowercase
#option-7 {
	font-family: Raleway;
	font-weight: 500;
	text-transform: lowercase;

	.navbar-brand {
		font-weight: 700;
		font-family: Raleway;
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-8 {
	font-family: Montserrat;
	font-weight: 700;

	.navbar-brand {
		font-weight: 700;
		font-family: Montserrat;
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
	text-transform: lowercase;
}

#option-9 {
	font-family: Roboto;
	font-weight: 400;
	text-transform: lowercase;

	.navbar-brand {
		font-weight: 500;
		font-family: Roboto;
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-10 {
	font-family: 'Roboto Condensed';
	font-weight: 400;
	text-transform: lowercase;

	.navbar-brand {
		font-weight: 700;
		font-family: 'Roboto Condensed';
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-11 {
	font-family: 'Open Sans';
	font-weight: 500;
	text-transform: lowercase;

	.navbar-brand {
		font-weight: 700;
		font-family: 'Open Sans';
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}

#option-12 {
	font-family: Lato;
	font-weight: 400;
	text-transform: lowercase;

	.navbar-brand {
		font-weight: 700;
		font-family: Lato;
	}

	.equipment {
		font-weight: 400;
	}

	.navbar-nav > li > a {
		font-weight: 400;
	}
}
*/