.language-switcher {
	a {
		color: @black;
		transition: all .3s;
		font-weight: 600;
		font-size: 14px;

		&:hover,
		&.active {
			border-bottom: 2px solid @black;
			text-decoration: none;
		}
	}

	.english {
		margin-right: 5px;
	}	

	.thai {
		margin-left: 5px;
	}
}

.home .language-switcher {
	a {
		border-color: #FFF;
		color: #FFF;

		&:hover,
		&.active {
			border-bottom: 2px solid #FFF;
		}
	}
}